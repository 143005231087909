import React, { ReactElement, ReactNode } from 'react';
import { DataSchema } from '../../services/Integration';
import { LiquidTemplateInput } from '../../components/LiquidTemplateInput';
import get from 'lodash/get';
import set from 'lodash/set';
import { FormattedMessage } from 'react-intl';
import { jsonSchemaGet } from '@tactiq/model';

export type UiSchemaItem = {
  path: string;
  label?: () => ReactNode;
};

export type UiSchema = Array<UiSchemaItem>;

/**
 * Render a form from a JsonSchema object
 */
export function JsonForm(props: {
  schema: DataSchema;
  value: unknown;
  onChange: (next: unknown) => void;
  disabled: boolean;
  objectKey?: string;
  uiSchema?: UiSchema;
  path?: string[];
}): ReactElement | null {
  const {
    disabled,
    schema,
    value,
    onChange,
    objectKey,
    uiSchema,
    path = [],
  } = props;
  const stateProps = { value, onChange, disabled, objectKey };
  const { type } = schema;

  // Ui schema is non-recursive
  if (uiSchema) {
    return (
      <>
        {uiSchema.map(({ path: itemPath, label }) => {
          const itemSchema = jsonSchemaGet(schema, itemPath);

          if (!itemSchema)
            throw new Error(`Could not find schema at: ${itemPath.toString()}`);

          return (
            <StringInput
              key={itemPath}
              schema={itemSchema}
              label={label?.()}
              isArray={itemSchema.type === 'array'}
              onChange={(next) => {
                onChange(
                  // @TODO Figure out how to properly type the json schema
                  set({ ...(value as Record<string, unknown>) }, itemPath, next)
                );
              }}
              value={get(value, itemPath, '')}
              disabled={disabled}
            />
          );
        })}
      </>
    );
  }

  switch (type) {
    case 'object': {
      // @TODO Figure out how to properly type the json schema
      const props = stateProps as {
        value: Record<string, unknown>;
        onChange: (next: Record<string, unknown>) => void;
        disabled: boolean;
      };
      if (!schema.properties) return null;
      return (
        <ObjectForm
          path={path}
          uiSchema={uiSchema}
          properties={schema.properties}
          {...props}
        />
      );
    }

    case 'number':
    case 'integer':
    case 'string':
    case 'boolean':
    case 'array': {
      // if (schema.referenceCollection ?? schema.items?.referenceCollection)
      //   return null;

      return (
        <StringInput
          schema={schema}
          isArray={type === 'array'}
          {...stateProps}
        />
      );
    }

    default:
      return null;
  }
}

function ObjectForm(props: {
  value: Record<string, unknown>;
  onChange: (next: Record<string, unknown>) => void;
  properties: NonNullable<DataSchema['properties']>;
  disabled: boolean;
  path: string[];
  uiSchema?: UiSchema;
}) {
  const { properties, value, onChange, disabled, path, uiSchema } = props;
  return (
    <>
      {Object.entries(properties)
        .filter(([, schema]) => !schema.readOnly)
        .map(([key, schema]) => {
          const defaultValue = schema.type === 'object' ? {} : '';
          return (
            <JsonForm
              key={key}
              objectKey={key}
              schema={schema}
              value={value[key] ?? defaultValue}
              onChange={(next) => onChange({ ...value, [key]: next })}
              disabled={disabled}
              path={path.concat(key)}
              uiSchema={uiSchema}
            />
          );
        })}
    </>
  );
}

function StringInput({
  schema,
  value,
  onChange,
  disabled,
  objectKey,
  label,
  isArray,
}: {
  schema: DataSchema;
  value: string | unknown;
  onChange: (next: string) => void;
  objectKey?: string;
  disabled: boolean;
  label?: ReactNode;
  isArray: boolean;
}) {
  const isReference =
    schema.referenceCollection || schema.items?.referenceCollection;
  return (
    <div className="flex h-full w-full flex-col gap-1">
      <div className="flex min-w-32 items-end justify-between text-sm font-medium">
        {label ?? schema.title ?? schema.description ?? objectKey}
        <span className="text-xs text-slate-400">
          {isReference ? (
            isReference.key
          ) : isArray ? (
            <FormattedMessage defaultMessage="List" />
          ) : (
            <FormattedMessage defaultMessage="Text" />
          )}
        </span>
      </div>
      <LiquidTemplateInput
        workflowId=""
        className="min-h-16"
        value={String(value)}
        onChange={(next) => onChange(next)}
        disabled={disabled}
        isArray={isArray}
      />
    </div>
  );
}
