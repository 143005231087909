import React from 'react';
import { ChevronRightIcon, ChevronLeftIcon } from 'lucide-react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';
import { cx } from '../../helpers/utils';

export type BreadcrumbItem = {
  href: string;
  label: React.ReactNode;
};

export const Breadcrumbs: React.FC<{
  backHref: string;
  items: BreadcrumbItem[];
}> = ({ backHref, items }) => {
  const showBack = backHref || null;
  const navigate = useNavigate();

  return (
    <div>
      {showBack && (
        <nav className="sm:hidden" aria-label="Back">
          <a
            href={backHref}
            className="flex items-center text-sm font-medium text-slate-500 hover:text-slate-700"
          >
            <ChevronLeftIcon
              className="-ml-1 mr-1 h-5 w-5 flex-shrink-0 text-slate-400"
              aria-hidden="true"
            />
            <FormattedMessage defaultMessage="Back" id="cyR7Kh" />
          </a>
        </nav>
      )}
      <nav className="hidden sm:flex" aria-label="Breadcrumb">
        <ol role="list" className="flex items-center space-x-4">
          {items.map((item, index) => (
            <li key={item.href}>
              <div className="flex items-center">
                {index > 0 ? (
                  <ChevronRightIcon
                    className="h-5 w-5 flex-shrink-0 text-slate-400"
                    aria-hidden="true"
                  />
                ) : null}
                <a
                  onClick={() => {
                    navigate(item.href);
                  }}
                  className={cx(
                    index > 0 ? 'ml-4' : '',
                    'cursor-pointer text-sm font-medium text-slate-500 hover:text-slate-700'
                  )}
                >
                  {item.label}
                </a>
              </div>
            </li>
          ))}
        </ol>
      </nav>
    </div>
  );
};
