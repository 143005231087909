import { useMutation, useQuery } from '@apollo/client';
import { TeamRole } from '@tactiq/model';
import {
  HelpCircle,
  MoreVertical,
  Settings,
  Settings2,
  UserPlus2,
  FileSpreadsheet,
} from 'lucide-react';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Alert } from '../../../components/Alert';
import { Button } from '../../../components/buttons';
import { Menu } from '../../../components/Menu';
import {
  ChangeMemberRolesDocument,
  GetTeamPlanDetailsDocument,
  RequestUpgradeDocument,
  TeamMember,
  TeamMemberStatus,
  UpdateMemberDocument,
  UpgradeToTeamDocument,
} from '../../../graphql/operations';
import {
  TierPricingDialogSource,
  trackTeamViewUpgradeDialogViewed,
  trackTeamUpgradePendingMember,
  trackTeamProUpgradeRequested,
  trackTeamChangeMemberRoles,
  trackTeamSaveAsCSVClicked,
  trackWebEvent,
} from '../../../helpers/analytics';
import { kBilling, kSettingsTeam, kTeam } from '../../../helpers/routes';
import { gotTeam } from '../../../redux/modules/user';
import {
  selectTeam,
  selectUid,
  selectUserPlan,
  selectUserTier,
} from '../../../redux/selectors';
import { TeamCancellationDialog } from '../../Credits/CancellationDialog';
import { AddMembersModal } from './AddMembers';
import { AddTeamSeatsDialog } from './AddTeamSeatsDialog';
import { useBuyMultipleSeatsButton } from './BuyMultipleSeats';
import { Member } from './Member';
import { TierPricingDialog } from '../../Credits/TierPricing/TierPricingDialog';
import { Tooltip } from '../../../components/Tooltip';
import { Table } from '../../../components/Table';
import { Avatar } from '../../../components/Avatar';
import { Chip } from '../../../components/Chips';
import { saveAsCsv } from '../../../helpers/save-as-csv';

export interface TeamViewProps {
  teamId: string;
  isAddMemberModalOpen: boolean;
}

/**
 * Team View
 * @param {unknown} param0 params
 * @param {string} param0.teamId team id
 * @param {boolean} param0.isAddMemberModalOpen is add member modal open
 * @returns {React.FC} a component
 */
const TeamView: React.FC<TeamViewProps> = ({
  teamId,
  isAddMemberModalOpen,
}) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement>(null);
  const team = useSelector(selectTeam);
  const plan = useSelector(selectUserPlan);
  const userId = useSelector(selectUid);
  const userTier = useSelector(selectUserTier);
  const isAdmin =
    team?.members.some((m) => m.uid === userId && m.roles.ADMIN) ?? false;
  const teamHasOtherAdmins =
    team?.members.some((m) => m.uid !== userId && m.roles.ADMIN) ?? false;
  const isPaidAdmin = Boolean(
    isAdmin && plan.paid?.isPaid && plan.paid.__typename === 'StripePaidPlan'
  );
  const isPaidTeam = team?.isPaid || isPaidAdmin;
  const teamSeatUsersCount =
    team?.members.filter((m) => m.status === TeamMemberStatus.TEAM).length ?? 1;
  const remainingSeats = Math.max(
    (team?.plan?.quantity ?? 0) - teamSeatUsersCount,
    0
  );

  const [showTierPricingDialog, setShowTierPricingDialog] =
    React.useState<boolean>(false);

  const dispatch = useDispatch();
  const [isCancellationDialogOpen, setIsCancellationDialogOpen] =
    useState(false);

  const showUpgradeDialog = useCallback(
    (memberId?: string, isAllMembers?: boolean) => {
      setShowTierPricingDialog(true);

      trackTeamViewUpgradeDialogViewed({
        isIndividualMember: Boolean(memberId),
        isAllMembers: isAllMembers ?? false,
      });
    },
    []
  );
  const hideUpgradeDialog = useCallback(() => {
    setShowTierPricingDialog(false);
  }, []);
  const showAddMemberModal = useCallback(() => {
    navigate(`/team/invite`);
  }, [navigate]);
  const hideAddMemberModal = useCallback(() => {
    navigate(kTeam, { state: { replace: true } });
  }, [navigate]);

  const [teamUpdateMember] = useMutation(UpdateMemberDocument);
  const [upgradeToTeam] = useMutation(UpgradeToTeamDocument);
  const getTeamPlanDetails = useQuery(GetTeamPlanDetailsDocument, {
    skip: true,
  });
  const [requestUpgrade] = useMutation(RequestUpgradeDocument);

  const [upgradeTeamMemberQuantity, setUpgradeTeamMemberQuantity] = useState(0);
  const [memberToUpgrade, setMemberToUpgrade] = useState<
    TeamMember | undefined
  >();
  const [isUpgradeTeamMemberDialogOpen, setIsUpgradeTeamMemberDialogOpen] =
    useState(false);

  const upgradeTeamMember = useCallback(
    async (member: TeamMember) => {
      if (member.status === TeamMemberStatus.PENDING) {
        enqueueSnackbar(
          intl.formatMessage({
            defaultMessage: 'The member must join the team first.',
            id: 'ZdAWp5',
          }),
          { variant: 'INFO' }
        );
        trackTeamUpgradePendingMember();
      } else {
        dispatch(
          gotTeam(
            (
              await teamUpdateMember({
                variables: {
                  input: {
                    memberId: member.uid,
                    status: TeamMemberStatus.TEAM,
                  },
                },
              })
            ).data?.team_updateMember
          )
        );
      }
    },
    [intl, dispatch, teamUpdateMember]
  );

  const onUpgrade = useCallback(
    async (member: TeamMember, isRequest: boolean) => {
      if (isRequest) {
        enqueueSnackbar(
          intl.formatMessage({
            defaultMessage:
              'We have notified the team owner about your request.',
            id: '1jlwpY',
          }),
          { variant: 'INFO' }
        );

        trackTeamProUpgradeRequested(teamId);

        await requestUpgrade();

        return;
      } else if (!isPaidTeam) {
        return showUpgradeDialog(member.uid);
      }

      if (isPaidTeam && remainingSeats) {
        await upgradeTeamMember(member);
      } else if (team?.plan?.isCustomContract) {
        enqueueSnackbar(
          intl.formatMessage({
            defaultMessage:
              'You cannot change seat count on a custom plan, please reach out to the sales team.',
            id: 'blzxa8',
          }),
          { variant: 'INFO' }
        );
      } else {
        setMemberToUpgrade(member);
        setUpgradeTeamMemberQuantity(
          1 + (member.uid !== userId && isPaidAdmin ? 1 : 0)
        );
        setIsUpgradeTeamMemberDialogOpen(true);
      }
    },
    [
      isPaidTeam,
      remainingSeats,
      intl,
      teamId,
      requestUpgrade,
      showUpgradeDialog,
      upgradeTeamMember,
      userId,
      isPaidAdmin,
      team,
    ]
  );

  const onDowngrade = useCallback(
    async (member: TeamMember) => {
      const details = (await getTeamPlanDetails.refetch()).data.teamPlanDetails;
      if (details?.seatsAllocated === 1) {
        setIsCancellationDialogOpen(true);
        return;
      }
      if (member.status === TeamMemberStatus.PRO && userId) {
        showUpgradeDialog(userId);
      } else {
        dispatch(
          gotTeam(
            (
              await teamUpdateMember({
                variables: {
                  input: {
                    memberId: member.uid,
                    status: TeamMemberStatus.FREE,
                  },
                },
              })
            ).data?.team_updateMember
          )
        );
      }
    },
    [getTeamPlanDetails, userId, showUpgradeDialog, dispatch, teamUpdateMember]
  );

  const [changeMemberRoles] = useMutation(ChangeMemberRolesDocument);

  const onChangeMemberRoles = useCallback(
    async (
      member: TeamMember,
      roles: Omit<TeamMember['roles'], 'COLLABORATOR'>
    ) => {
      if (
        member.uid !== userId ||
        confirm(
          'You will lose access to your team if you change your role. Are you sure?'
        )
      ) {
        dispatch(
          gotTeam(
            (
              await changeMemberRoles({
                variables: {
                  input: {
                    memberId: member.uid,
                    roles,
                  },
                },
              })
            ).data?.team_changeMemberRoles
          )
        );
        trackTeamChangeMemberRoles();
        enqueueSnackbar(
          intl.formatMessage({ defaultMessage: 'Success!', id: 'UIDXdt' }),
          {
            variant: 'INFO',
          }
        );
      }
    },
    [changeMemberRoles, dispatch, intl, userId]
  );

  useEffect(() => {
    inputRef.current?.focus();
  });

  const { button: btnBuyMultipleSeats, modal: multipleSeatsModal } =
    useBuyMultipleSeatsButton();

  const btnInviteMembers =
    team && team.members.length > 0 ? (
      <Button
        startIcon={<UserPlus2 className="h-4 w-4 text-white" />}
        onClick={showAddMemberModal}
      >
        <FormattedMessage defaultMessage="Invite members" id="ApULhK" />
      </Button>
    ) : null;

  const btnSettings = isAdmin ? (
    <Button
      startIcon={<Settings className="h-4 w-4 text-white" />}
      href={kSettingsTeam}
    >
      <FormattedMessage defaultMessage="Settings" id="D3idYv" />
    </Button>
  ) : null;

  const btnManageBilling =
    isAdmin && team && team.plan ? (
      <Button
        startIcon={<Settings2 className="h-4 w-4 text-white" />}
        href={kBilling}
      >
        <FormattedMessage defaultMessage="Manage billing" id="wLCwU4" />
      </Button>
    ) : null;

  if (!team || !userId) {
    return null;
  }

  const teamHasUsableSeats =
    isAdmin &&
    team.isPaid &&
    (team.plan?.quantity ?? 0) >
      team.members.filter((member) => member.status === TeamMemberStatus.TEAM)
        .length;

  const saveTeamData = () => {
    trackTeamSaveAsCSVClicked();

    saveAsCsv(
      ['name', 'email', 'roles', 'status'],
      team.members.map((member) => {
        const rolesList = Object.entries(member.roles)
          .filter(([role, isOn]) => isOn && role && !role.startsWith('__'))
          .map(([role]) => role)
          .join('|');
        return [member.displayName, member.email, rolesList, member.status];
      }),
      'Tactiq_team_members.csv'
    );
  };

  const renderUserListActions = () => {
    return isAdmin ? (
      <Button
        startIcon={<FileSpreadsheet className="h-4 w-4 text-current" />}
        onClick={saveTeamData}
        variant="outlined"
      >
        <FormattedMessage defaultMessage="Save as CSV" id="YYMipA" />
      </Button>
    ) : null;
  };

  return (
    <>
      <div className="flex w-full flex-col items-start gap-2 lg:flex-row lg:items-center">
        <h1 className="flex-1 text-3xl font-bold">
          <FormattedMessage defaultMessage="Team members" id="Cg1T7H" />
        </h1>
        <div className="flex items-center gap-2 self-end lg:self-center">
          {btnInviteMembers}
          {btnBuyMultipleSeats && <div>{btnBuyMultipleSeats}</div>}
          {btnSettings && <div className="hidden xl:block">{btnSettings}</div>}
          {btnBuyMultipleSeats && (
            <div className="hidden xl:block">{btnManageBilling}</div>
          )}
          {btnSettings || btnManageBilling ? (
            <div className="xl:hidden">
              <Menu>
                <Menu.Trigger>
                  <Button variant="naked">
                    <span className="sr-only">More</span>
                    <MoreVertical
                      className="h-5 w-5 text-slate-500"
                      aria-hidden="true"
                    />
                  </Button>
                </Menu.Trigger>

                {btnSettings && (
                  <Menu.Item
                    as="link"
                    icon={<Settings className="h-4 w-4" />}
                    to={kSettingsTeam}
                  >
                    <FormattedMessage defaultMessage="Settings" id="D3idYv" />
                  </Menu.Item>
                )}
                {btnManageBilling && (
                  <Menu.Item
                    as="link"
                    icon={<Settings2 className="h-4 w-4" />}
                    to={kBilling}
                  >
                    <FormattedMessage
                      defaultMessage="Manage billing"
                      id="wLCwU4"
                    />
                  </Menu.Item>
                )}
              </Menu>
            </div>
          ) : null}
        </div>
      </div>

      {teamHasUsableSeats && (
        <Alert
          severity="info"
          description={
            <FormattedMessage
              defaultMessage="Your package includes {size} users. You can add or upgrade up to {users} extra team members using your plan without extra charges."
              id="7RmLjB"
              description="Team view. Package includes"
              values={{
                size: <b>{team.plan?.quantity}</b>,
                users: <b>{(team.plan?.quantity ?? 0) - teamSeatUsersCount}</b>,
              }}
            />
          }
        />
      )}

      <Table
        search={true}
        data={team.members}
        initialPageSize={100}
        columns={[
          {
            id: 'name',
            accessorFn: (row) => `${row.displayName}${row.email}`,
            enableSorting: true,
            header: () => (
              <FormattedMessage defaultMessage="Name" id="HAlOn1" />
            ),
            cell: ({ row: { original: member } }) => {
              const isCurrentUser = userId === member.uid;
              return (
                <div className="flex flex-row items-center gap-3">
                  <Avatar
                    src={member.photoURL}
                    name={member.displayName}
                    className="flex-shrink-0"
                  />
                  <div className="flex flex-col text-xs">
                    <strong>
                      {member.displayName}
                      {isCurrentUser ? ` (you)` : ''}
                    </strong>
                    <span>{member.email}</span>
                  </div>
                </div>
              );
            },
          },
          {
            id: 'role',
            accessorFn: (member) => {
              if (member.status === TeamMemberStatus.PENDING) return [];
              return Object.values(TeamRole)
                .filter((r) => member.roles[r] && r !== TeamRole.COLLABORATOR)
                .map((v) => (v === TeamRole.ADMIN ? 'Team Admin' : undefined))
                .filter((v) => v);
            },
            enableColumnFilter: true,
            enableSorting: true,
            meta: {
              ascLabel: (
                <FormattedMessage
                  defaultMessage="Team admins first"
                  id="GJT7oY"
                />
              ),
              descLabel: (
                <FormattedMessage
                  defaultMessage="Team admins last"
                  id="tesuYF"
                />
              ),
              filterLabel: (
                <FormattedMessage defaultMessage="Roles" id="c35gM5" />
              ),
            },
            header: () => (
              <div className="flex items-center gap-0.5">
                <FormattedMessage defaultMessage="Roles" id="c35gM5" />
                <Tooltip
                  title={
                    <FormattedMessage
                      defaultMessage="Roles control access to team features."
                      id="c9Hucc"
                      description="Team view. Roles info description"
                    />
                  }
                >
                  <a
                    href="https://help.tactiq.io/en/articles/9171435-types-of-roles-in-your-tactiq-team"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <HelpCircle size="1rem" />
                  </a>
                </Tooltip>
              </div>
            ),
            sortingFn: (prev, next, columnId) => {
              const a = prev.getValue(columnId) as string[];
              const b = next.getValue(columnId) as string[];
              return b.length - a.length;
            },
            cell: (row) => {
              return (
                <div className="flex items-center gap-2">
                  {[...(row.getValue() as string[])].reverse().map((role) =>
                    role === 'Team Admin' ? (
                      <Chip key={role} color="yellow">
                        <FormattedMessage
                          defaultMessage="Team Admin"
                          id="1YhWYw"
                        />
                      </Chip>
                    ) : null
                  )}
                </div>
              );
            },
          },
          {
            id: 'statusAndActions',
            enableColumnFilter: true,
            accessorFn: (member) => {
              return Object.values(TeamMemberStatus)
                .filter((r) => member.status === r)
                .map((v): string => {
                  switch (v) {
                    case 'TEAM':
                      return 'Team';
                    case 'PRO':
                      return 'Pro';
                    case 'FREE':
                      return 'Free';
                    case 'PENDING':
                      return 'Not joined the team yet';
                    default:
                      return '';
                  }
                });
            },
            meta: {
              filterLabel: (
                <FormattedMessage defaultMessage="Plan" id="fz0z4c" />
              ),
            },
            header: () => (
              <div className="flex items-center gap-0.5">
                <FormattedMessage defaultMessage="Plan" id="fz0z4c" />
                <Tooltip
                  title={
                    <FormattedMessage
                      defaultMessage="Users with Free Plan are free of charge. Users with Pro Plan consume paid seats."
                      id="s4lpGT"
                      description="Team view. Paid plan info description"
                    />
                  }
                >
                  <HelpCircle size="1rem" />
                </Tooltip>
              </div>
            ),
            cell: ({ row: { original: member } }) => {
              return (
                <Member
                  key={member.email}
                  isAdmin={isAdmin}
                  teamHasOtherAdmins={teamHasOtherAdmins}
                  isPaidTeam={isPaidTeam}
                  userId={userId}
                  team={team}
                  member={member}
                  onUpgrade={onUpgrade}
                  onDowngrade={onDowngrade}
                  onChangeRoles={onChangeMemberRoles}
                />
              );
            },
          },
        ]}
        actions={renderUserListActions}
      />

      {isAddMemberModalOpen && (
        <AddMembersModal
          team={team}
          open={isAddMemberModalOpen}
          onClose={hideAddMemberModal}
        />
      )}

      {isAdmin && (
        <>
          {multipleSeatsModal}
          <TeamCancellationDialog
            open={isCancellationDialogOpen}
            onClose={() => setIsCancellationDialogOpen(false)}
          />

          {showTierPricingDialog && (
            <TierPricingDialog
              onClose={hideUpgradeDialog}
              teamTier={team?.tier}
              teamSpecific
              userTier={userTier}
              source={TierPricingDialogSource.TEAM}
            />
          )}
        </>
      )}

      {isUpgradeTeamMemberDialogOpen && (
        <AddTeamSeatsDialog
          membersCount={upgradeTeamMemberQuantity}
          open={isUpgradeTeamMemberDialogOpen}
          onClose={() => setIsUpgradeTeamMemberDialogOpen(false)}
          onConfirm={async () => {
            if (memberToUpgrade) {
              if (!team.isPaid && memberToUpgrade.uid === userId) {
                await upgradeToTeam({
                  variables: {
                    input: {},
                  },
                });
              } else if (team.isPaid) {
                await upgradeTeamMember(memberToUpgrade);
                trackWebEvent('Subscription Upgraded', {
                  user: memberToUpgrade.uid,
                  team_id: teamId,
                  source: 'upgrade member to team seat',
                  old_plan: memberToUpgrade.status,
                  new_plan: TeamMemberStatus.TEAM,
                } satisfies Record<
                  'user' | 'team_id' | 'source' | 'old_plan' | 'new_plan',
                  unknown
                >);
              }
            }
          }}
        />
      )}
    </>
  );
};

export default TeamView;
