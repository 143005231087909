import React, { ReactElement } from 'react';
import { SingleSourceNode, WorkflowNodeProps } from './BaseNode';
import { Target } from 'lucide-react';

export function StartNode(props: WorkflowNodeProps): ReactElement {
  return (
    <SingleSourceNode
      icon={
        <Target className="size-8 rounded-lg bg-violet-50 p-1.5 text-violet-500 border border-violet-400/15" />
      }
      workflowNode={props}
      hideId
      noResize
      minHeight={60}
    />
  );
}
