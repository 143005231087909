import React from 'react';
import { trackWebEvent } from '../../../../helpers/analytics';
interface Props {
  destinations: Array<any>;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
  share: Function;
}

export const RecentDestinationList: React.FC<Props> = (props) => {
  const { destinations } = props;

  return (
    <div className="flex flex-row gap-2">
      {destinations.map((x) => (
        <RecentDestination
          key={x.sharedAt}
          destination={x}
          share={props.share}
        />
      ))}
    </div>
  );
};

interface RecentDestinationProps {
  destination: any;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
  share: Function;
}

function RecentDestination(props: RecentDestinationProps) {
  const { integration, connection, options } = props.destination;
  const customTitle =
    integration.getSharingDestinationTitle &&
    integration.getSharingDestinationTitle(options);

  return (
    <div
      className="hover:text-shadow-[0px 0px 2px hsl(161deg 85% 23%)] flex w-[360px] min-w-0 cursor-pointer items-center gap-2 rounded-s border border-slate-200 bg-white p-2 filter-none hover:border hover:bg-[#5928E5] hover:text-white hover:opacity-90"
      onClick={() => {
        trackWebEvent('Clicked on a recent sharing destination', {
          destination: integration.id,
        });
        props.share(integration, connection, options);
      }}
    >
      <img src={integration.icon} alt={integration.title} className="h-8 w-8" />
      <div className="flex max-w-full flex-col truncate">
        <div className="truncate">{customTitle ?? integration.title}</div>
        <div className="truncate text-sm">
          {customTitle ? integration.title : integration.shareDescription}
        </div>
      </div>
    </div>
  );
}
