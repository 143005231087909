import React, { ReactElement } from 'react';
import { RadioGroup } from '@headlessui/react';
import { cx } from '../../helpers/utils';

function Radio({
  value,
  label,
  description,
  disabled = false,
}: {
  value: string;
  label: string | ReactElement;
  description?: string | ReactElement;
  disabled?: boolean;
}): ReactElement {
  return (
    <RadioGroup.Option
      disabled={disabled}
      value={value}
      className={cx(
        'flex items-center gap-2 p-1 focus:outline-none focus:ring-2 focus:ring-brand-600 focus:ring-offset-1',
        !disabled ? 'cursor-pointer' : ''
      )}
    >
      {({ checked }) => (
        <>
          <span
            className={cx(
              'flex h-4 w-4 items-center justify-center rounded-full border-2',
              checked ? 'border-brand-600' : 'border-slate-400',
              disabled ? 'opacity-50' : ''
            )}
          >
            <span
              className={cx(
                'bg-trasparent inline-block h-2 w-2 rounded-full',
                checked ? 'bg-brand-600' : '',
                disabled ? 'opacity-50' : ''
              )}
            />
          </span>
          <div className={disabled ? 'opacity-50' : ''}>
            <RadioGroup.Label>{label}</RadioGroup.Label>
            {description ? (
              <RadioGroup.Description className="text-xs">
                {description}
              </RadioGroup.Description>
            ) : null}
          </div>
        </>
      )}
    </RadioGroup.Option>
  );
}

export { Radio, RadioGroup };
