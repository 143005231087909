import {
  IntegrationId,
  MeetingShareData,
  MeetingReach as ModelMeetingReach,
  RawTranscript,
  getParticipantsNames,
} from '@tactiq/model';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useNavigate } from 'react-router';
import { useAsyncEffect } from 'use-async-effect';
import { updateMeeting } from '../../../../graphql/meetings';
import {
  MeetingReach,
  MeetingShareSettings,
  UserIntegrationConnection,
} from '../../../../graphql/operations';
import { trackWebEvent } from '../../../../helpers/analytics';
import { saveMeetingShare } from '../../../../helpers/api/integrations/share';
import { copyToClipboard } from '../../../../helpers/clipboard';
import { baseURL } from '../../../../helpers/firebase/config';
import {
  IntegrationImplementation,
  RecentDestination,
} from '../../../../models/integration';
import { FullMeeting, TranscriptBlock } from '../../../../models/meeting';
import {
  RateUsModalSource,
  showRateUsModal,
} from '../../../../redux/modules/global';
import { getMeeting } from '../../../../redux/sagas/getMeeting';
import {
  createSelectTranscriptHighlightsSelector,
  selectTeam,
} from '../../../../redux/selectors';
import { RootState, TactiqStore } from '../../../../redux/store';
import { AllIntegrations } from '../../../Integration/integrations';
import { Loading } from '../../../Landing/Loading/LoadingComponent';
import ConnectionDialog from './ConnectionDialog';
import { DestinationList } from './DestinationList';
import { RecentDestinationList } from './RecentDestinationList';
import { ShareModal } from './common/ShareModal';
import {
  ShareContent,
  ShareLinkViewSettings,
  ShareType,
  fireShareCompletedAnalytics,
} from '../../common/meeting-hooks';
import { Checkbox } from '../../../../components/Checkbox';

interface Props {
  title: string;
  meeting: FullMeeting;
  rawTranscript: RawTranscript;
  blocks: TranscriptBlock[];
  userConnections: UserIntegrationConnection[];
  recentDestinations: RecentDestination[];
  possibleDestinations: IntegrationImplementation[];
  integrationId: string | undefined;
  emails: string[];
  reach?: MeetingReach;
}

/**
 * Share meeting Impl
 * @param {unknown} props props
 * @returns {React.FC} a component
 */
export const ShareMeetingImpl: React.FC<Props> = (props) => {
  const {
    title,
    recentDestinations,
    possibleDestinations,
    userConnections,
    meeting,
    blocks,
    rawTranscript,
    integrationId,
    emails,
    reach,
  } = props;
  const { id: meetingId } = meeting;
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const store = useStore() as unknown as TactiqStore;
  const team = useSelector(selectTeam);

  const [isSharingDetails, setIsSharingDetails] = useState(true);
  const [isSharingTranscript, setIsSharingTranscript] = useState(true);
  const [isSharingNotes, setIsSharingNotes] = useState(true);
  const [showConnectionDialog, setShowConnectionDialog] = useState<
    string | null
  >(null);
  const [selectedIntegrationId, setSelectedIntegrationId] = useState<
    string | undefined
  >(integrationId);
  const [inProgress, setInProgress] = useState(false);

  const integrationToConnect: IntegrationImplementation | void =
    AllIntegrations.find(
      (x: IntegrationImplementation) => x.id === showConnectionDialog
    );
  const selectedIntegration = AllIntegrations.find(
    (x: IntegrationImplementation) => x.id === selectedIntegrationId
  );
  const selectTranscriptHighlights = useMemo(
    createSelectTranscriptHighlightsSelector,
    []
  );
  const highlights =
    useSelector((state: RootState) =>
      selectTranscriptHighlights(state, meeting.id)
    ) ?? [];
  const ShareDialog = selectedIntegration?.ShareDialog;

  useAsyncEffect(
    async () => {
      if (userConnections?.find((x) => x.type === showConnectionDialog)) {
        const integration = possibleDestinations.find(
          (x) => x.id === showConnectionDialog
        );

        if (integration) {
          await shareToDestination(integration);
        }

        setShowConnectionDialog(null);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userConnections]
  );

  useEffect(() => {
    setShowConnectionDialog(null);
    setSelectedIntegrationId(integrationId);
    setInProgress(false);
  }, [meetingId, integrationId]);

  const share = useCallback(
    async (
      integration: IntegrationImplementation,
      connection: UserIntegrationConnection,
      options: Partial<
        MeetingShareSettings & {
          emails?: string[];
          reach?: MeetingReach;
          analyticsAttributes?: {
            share_type: ShareType;
            recipients_email_count: number;
            recipients_email_total: number;
          };
        }
      > = {},
      keepOpen?: boolean
    ) => {
      if (!integration.share) {
        return;
      }

      if (!keepOpen) {
        setSelectedIntegrationId(undefined);
        setInProgress(true);
      }

      const googleDriveLink = meeting.transcripts?.find(
        (link) => link.__typename === 'GoogleDriveMeetingTranscriptLink'
      );

      const sharingOptions = {
        meetingId,
        rawTranscript,
        meetingDetails: {
          title,
          start: new Date(rawTranscript.createdAt).toLocaleString(),
          startMillis: blocks?.length
            ? blocks[0].timestamp
            : rawTranscript.createdAt,
          duration: blocks?.length
            ? blocks[blocks.length - 1].fromStartHM
            : 'No transcript was captured',
          durationMillis: blocks?.length
            ? blocks[blocks.length - 1].timestamp - blocks[0].timestamp
            : 0,
          participants: getParticipantsNames(meeting.participants).join(', '),
        },
        highlights,
        blocks,
        title,
        isSharingDetails,
        isSharingHighlights: false,
        isSharingTranscript,
        isSharingNotes,
        footerLink:
          googleDriveLink?.externalLink ??
          `${baseURL}/#/transcripts/${meetingId}`,
        footerLinkText: 'Full Transcript',
        aiOutputs: meeting.aiOutputs,
        ...options,
      };

      trackWebEvent('Sharing attempted', {
        meetingId,
        isSharingDetails,
        isSharingHighlights: false,
        isSharingTranscript,
        isSharingNotes,
        ...options,
      });

      const result = await integration.share(
        connection,
        sharingOptions,
        keepOpen
      );

      if (!result) {
        return;
      }

      if (integration.id === 'link') {
        await updateMeeting({
          id: meetingId,
          rawTranscript,
          permissions: {
            emails: options.emails ?? [],
            reach: options.reach ?? MeetingReach.RESTRICTED,
            settings: {
              isSharingDetails,
              isSharingHighlights: false,
              isSharingTranscript,
              isSharingNotes,
            },
          },
        });
      } else if (integration.id !== 'googledrive') {
        const shareData = {
          connectionKey: connection.connectionKey,
          integrationId: integration.id as IntegrationId,
          link: result.link,
          meetingId,
          sharedAt: Date.now(),
          ...(result.externalId ? { externalId: result.externalId } : {}),
          title,
          options: {
            isSharingDetails,
            isSharingHighlights: false,
            isSharingNotes,
            isSharingTranscript,
            ...(options.reach
              ? {
                  reach:
                    options.reach === MeetingReach.PUBLIC
                      ? ModelMeetingReach.public
                      : ModelMeetingReach.restricted,
                }
              : {}),
            ...options,
          },
        };

        await saveMeetingShare(meetingId, shareData as MeetingShareData);
      }

      trackWebEvent('Sharing successful', {
        meetingId,
        isSharingDetails,
        isSharingHighlights: false,
        isSharingTranscript,
        isSharingNotes,
        ...options,
      });

      let shareType;
      if (integration.id === 'link') {
        shareType = options.analyticsAttributes?.share_type;
      } else {
        shareType = 'Integration';
      }

      fireShareCompletedAnalytics({
        team_id: team?.id,
        type: shareType as ShareType,
        integration: shareType === 'Integration' ? integration.id : undefined,
        content: [
          isSharingDetails && 'Details',
          isSharingTranscript && 'Transcript',
          isSharingNotes && 'Notes',
        ].filter(Boolean) as ShareContent[],
        recipients_email_count:
          shareType === 'Email'
            ? options.analyticsAttributes?.recipients_email_count
            : undefined, // current number of recipients,
        recipients_email_total:
          shareType === 'Email'
            ? options.analyticsAttributes?.recipients_email_total
            : undefined, // Total number of recipients,
        link_view_settings:
          shareType === 'Link' && options.reach
            ? ({
                [MeetingReach.PUBLIC]: 'Everyone',
                [MeetingReach.RESTRICTED]: 'Restricted',
              }[options.reach] as ShareLinkViewSettings)
            : undefined,
      });

      if (!result.keepOpen) {
        await copyToClipboard(
          result.link,
          intl.formatMessage({
            defaultMessage: 'Link copied to clipboard',
            id: 'PYdYT7',
            description: 'Toast message when link is copied to clipboard',
          })
        );

        navigate(`/transcripts/${meetingId}`);

        const meetingCount =
          Object.values(store.getState().global.meetings.my ?? {}).length ?? 0;

        if (meetingCount > 5) {
          dispatch(showRateUsModal(RateUsModalSource.SHARE_MEETING));
        }
        await getMeeting(store, meeting.id, meeting, { force: true });
      }
    },
    [
      meeting,
      meetingId,
      rawTranscript,
      title,
      blocks,
      highlights,
      isSharingDetails,
      isSharingTranscript,
      isSharingNotes,
      intl,
      navigate,
      dispatch,
      store,
    ]
  );

  const shareToDestination = useCallback(
    async (integration: IntegrationImplementation) => {
      const connections = userConnections.filter(
        (x) =>
          x.type === integration.id &&
          (integration.isConnected
            ? integration.isConnected({ userConnections })
            : true)
      );
      if (!connections.length) {
        trackWebEvent('Showing connection dialog for an integration', {
          destination: integration.id,
        });
        setShowConnectionDialog(integration.id);
      } else {
        if (integration.ShareDialog) {
          trackWebEvent('Showing share dialog for an integration', {
            destination: integration.id,
          });
          setSelectedIntegrationId(integration.id);
        } else {
          await share(integration, connections[0]);
        }
      }
    },
    [share, userConnections]
  );

  if (!rawTranscript || inProgress) {
    return (
      <ShareModal
        onClose={() => {
          setSelectedIntegrationId(undefined);
          if (integrationId) {
            navigate(`/transcripts/${meetingId}`);
          }
        }}
      >
        <div className="flex grow items-center justify-center gap-2">
          <Loading />
          <p className="text-lg">
            <FormattedMessage defaultMessage="Working..." id="H6RqOb" />
          </p>
        </div>
      </ShareModal>
    );
  }

  const showShareDialog = selectedIntegrationId && !showConnectionDialog;

  let content;

  if (showShareDialog && ShareDialog) {
    content = (
      <ShareModal
        onClose={() => {
          setSelectedIntegrationId(undefined);
          if (integrationId) {
            navigate(`/transcripts/${meetingId}`);
          }
        }}
      >
        <ShareDialog
          meeting={meeting}
          connection={
            userConnections.filter((x) => x.type === selectedIntegrationId)[0]
          }
          highlights={highlights}
          title={title}
          emails={emails}
          reach={reach}
          isSharingDetails={isSharingDetails}
          isSharingTranscript={isSharingTranscript}
          isSharingNotes={isSharingNotes}
          share={share}
        />
      </ShareModal>
    );
  } else {
    content = (
      <div className="flex flex-col gap-2">
        <h3 className="text-xl font-bold">
          <FormattedMessage
            defaultMessage="Share {title}"
            id="VLfMXO"
            description="Meeting share title."
            values={{ title }}
          />
        </h3>

        <div className="flex gap-6">
          <Checkbox
            id="share-highlights"
            checked={isSharingDetails}
            onChange={() => {
              setIsSharingDetails(!isSharingDetails);
              trackWebEvent('Toggle sharing setting: details', {
                state: !isSharingDetails,
              });
            }}
            label={
              <FormattedMessage
                defaultMessage="Meeting Details"
                id="O0Cp69"
                description="Meeting share details form label."
              />
            }
          />

          <Checkbox
            checked={isSharingNotes}
            onChange={() => {
              setIsSharingNotes(!isSharingNotes);
              trackWebEvent('Toggle sharing setting: notes', {
                state: !isSharingNotes,
              });
            }}
            id="share-notes"
            label={
              <FormattedMessage
                defaultMessage="Notes"
                description="Meeting share notes form label."
                id="kI2hmX"
              />
            }
          />

          <Checkbox
            checked={isSharingTranscript}
            onChange={() => {
              setIsSharingTranscript(!isSharingTranscript);
              trackWebEvent('Toggle sharing setting: transcript', {
                state: !isSharingTranscript,
              });
            }}
            id="share-transcript"
            label={
              <FormattedMessage
                defaultMessage="Transcript"
                description="Meeting share transcript form label."
                id="oz5mO1"
              />
            }
          />
        </div>

        {recentDestinations?.length ? (
          <>
            <h2>
              <FormattedMessage defaultMessage="Recent shares:" id="hPIfeG" />
            </h2>
            <RecentDestinationList
              destinations={recentDestinations}
              share={share}
            />
            <hr className="mt-3" />
          </>
        ) : null}

        <DestinationList
          destinations={possibleDestinations}
          userConnections={userConnections}
          share={(integration: IntegrationImplementation) =>
            shareToDestination(integration)
          }
        />

        {integrationToConnect ? (
          <ConnectionDialog
            integration={integrationToConnect}
            onClose={() => setShowConnectionDialog(null)}
          />
        ) : null}
      </div>
    );
  }

  return content;
};
