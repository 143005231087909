import { LanguageType } from '@tactiq/model';
import { FuseResult } from 'fuse.js';
import { ChevronRightIcon } from 'lucide-react';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { trackWebEvent } from '../../../../helpers/analytics';
import { kTranscriptsRedirect } from '../../../../helpers/routes';
import {
  FullMeeting,
  TransformedRawTranscript,
} from '../../../../models/meeting';
import { MeetingActions } from '../../card/MeetingActions';
import { ExtendedTranscriptBlock, ViewMode } from '../types';
import SearchBar from '../../common/SearchBar';
import { Tooltip } from '../../../../components/Tooltip';
import {
  ToggleButton,
  ToggleButtonGroup,
} from '../../../../components/ToggleButtonGroup';

export interface TranscriptViewMenuProps {
  meeting: FullMeeting;
  query: string;
  viewMode: ViewMode;
  detectedLanguage?: LanguageType;
  matches: FuseResult<ExtendedTranscriptBlock>[];
  translation?: TransformedRawTranscript['translation'];
  onChangeQuery: (query: string) => void;
  onMatchSelected: (match: FuseResult<ExtendedTranscriptBlock>) => void;
  onChangeViewMode: (viewMode: ViewMode) => void;
}

export const TranscriptViewMenu: React.FC<TranscriptViewMenuProps> = ({
  meeting,
  query,
  detectedLanguage,
  matches,
  translation,
  viewMode,
  onChangeQuery,
  onMatchSelected,
  onChangeViewMode,
}) => {
  const intl = useIntl();
  return (
    <div className="sticky top-[67px] z-10 flex flex-col gap-1 border-b-[1px] border-slate-200 bg-white pb-5 pt-2 md:top-0">
      <div className="flex flex-col items-center justify-between lg:flex-row">
        <nav className="flex" aria-label="Breadcrumb">
          <ol role="list" className="flex items-center">
            <li>
              <div className="flex items-center">
                <a
                  href={kTranscriptsRedirect}
                  className="whitespace-nowrap text-xs font-medium text-slate-500 hover:text-slate-700"
                  onClick={() => {
                    trackWebEvent('Back to transcripts clicked');
                  }}
                >
                  <FormattedMessage
                    defaultMessage="Transcripts"
                    id="5vuKGa"
                    description="All transcripts link label"
                  />
                </a>
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <ChevronRightIcon
                  className="h-4 w-4 flex-shrink-0 text-slate-400"
                  aria-hidden="true"
                />
                <a className="max-w-xs truncate text-xs font-medium text-slate-500 hover:text-slate-700">
                  {meeting.title}
                </a>
              </div>
            </li>
          </ol>
        </nav>
        <MeetingActions meeting={meeting} fullSize={true} />
      </div>
      <SearchBar
        placeholder={intl.formatMessage({
          defaultMessage: 'Search in transcript',
          id: 'swQKcE',
          description: 'Search in transcript text field label.',
        })}
        query={query}
        matches={matches}
        onChange={onChangeQuery}
        onMatchSelected={
          onMatchSelected as (match: FuseResult<unknown>) => void
        }
      />
      {translation && (
        <div className="mt-2">
          <ToggleButtonGroup value={viewMode} onChange={onChangeViewMode}>
            <Tooltip
              title={`${detectedLanguage?.optionName || 'English'} (Original)`}
            >
              <ToggleButton value={ViewMode.original}>
                {detectedLanguage ? detectedLanguage.shortName : 'EN'}
              </ToggleButton>
            </Tooltip>
            <Tooltip title={translation.detectedLanguage.optionName}>
              <ToggleButton value={ViewMode.translated}>
                {translation.detectedLanguage.shortName}
              </ToggleButton>
            </Tooltip>
            <ToggleButton value={ViewMode.combined}>
              <FormattedMessage
                defaultMessage="Combined"
                id="m3bzTP"
                description="Transcript message switch. Combined radio button label."
              />
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      )}
    </div>
  );
};
