import React, {
  Fragment,
  ReactElement,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  useFloating,
  useClick,
  useDismiss,
  useRole,
  useListNavigation,
  useInteractions,
  FloatingFocusManager,
  useTypeahead,
  offset,
  flip,
  size,
  autoUpdate,
  FloatingPortal,
} from '@floating-ui/react';
import { cx } from '../../helpers/utils';
import { Check, ChevronDown } from 'lucide-react';
import { FormattedMessage } from 'react-intl';
import { Spinner } from '../Spinner';
import { Tooltip } from '../Tooltip';

/**
 * Helper types to simplify conditional handling
 */
type SingleOrArray<T, Multiple extends boolean> = Multiple extends true
  ? T[]
  : T;
type OnChangeHandler<T, Multiple extends boolean> = (
  next: SingleOrArray<T, Multiple>
) => void;

/**
 * Define Select component props with conditional types for single/multiple select modes.
 */
type SelectProps<T, Multiple extends boolean = false> = {
  multiple?: Multiple;
  variant?: 'default' | 'naked';
  value: SingleOrArray<T, Multiple> | undefined;
  disabled?: boolean;
  full?: boolean;
  onChange: OnChangeHandler<T, Multiple>;
  options: Array<{
    value: T;
    label: string;
    icon?: ReactNode;
    divider?: 'top' | 'bottom';
    selectable?: boolean;
    tooltip?: boolean;
  }>;
  placeholder?: ReactNode;
  onOpenChange?: (open: boolean) => void;
  root?: HTMLElement | null | React.MutableRefObject<HTMLElement | null>;
  loading?: boolean;
};

export function Select<T, Multiple extends boolean = false>({
  value,
  options,
  onChange,
  placeholder,
  disabled,
  variant = 'default',
  full,
  onOpenChange,
  root,
  loading,
  multiple,
}: SelectProps<T, Multiple>): ReactElement {
  const [isOpen, setIsOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const getInitialSelectedIndexes = () => {
    if (multiple && Array.isArray(value)) {
      return options.reduce((acc, o, index) => {
        if (value.includes(o.value)) acc.push(index);
        return acc;
      }, [] as number[]);
    } else if (value !== null) {
      return [options.findIndex((o) => o.value === value)];
    } else {
      return [];
    }
  };

  const [selectedIndexes, setSelectedIndexes] = useState<number[]>(
    getInitialSelectedIndexes
  );

  useEffect(() => {
    setSelectedIndexes(getInitialSelectedIndexes());
  }, [options.length, value, multiple]);

  const handleOpenChange = (isOpen: boolean) => {
    setIsOpen(isOpen);
    onOpenChange?.(isOpen);
  };

  const { refs, floatingStyles, context } = useFloating<HTMLElement>({
    placement: 'bottom-start',
    open: isOpen,
    onOpenChange: handleOpenChange,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(5),
      flip({ padding: 10 }),
      size({
        apply({ rects, elements, availableHeight }) {
          Object.assign(elements.floating.style, {
            maxHeight: `${availableHeight}px`,
            minWidth: `${rects.reference.width}px`,
          });
        },
        padding: 10,
      }),
    ],
  });

  const listRef = useRef<Array<HTMLElement | null>>([]);
  const listContentRef = useRef(options.map((ii) => ii.label));
  const isTypingRef = useRef(false);

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [
      useClick(context, { event: 'mousedown' }),
      useDismiss(context),
      useRole(context, { role: 'listbox' }),
      useListNavigation(context, {
        listRef,
        activeIndex,
        selectedIndex: selectedIndexes.length ? selectedIndexes[0] : null,
        onNavigate: setActiveIndex,
        loop: true,
      }),
      useTypeahead(context, {
        listRef: listContentRef,
        activeIndex,
        selectedIndex: selectedIndexes.length ? selectedIndexes[0] : null,
        onMatch: isOpen ? setActiveIndex : undefined,
        onTypingChange(isTyping) {
          isTypingRef.current = isTyping;
        },
      }),
    ]
  );

  const handleSelect = (index: number) => {
    const selectable = options[index].selectable ?? true;
    const selectedOption = options[index]?.value; // Add a check for valid index

    if (multiple) {
      let newSelectedValues;
      if (Array.isArray(value)) {
        newSelectedValues = value.includes(selectedOption)
          ? value.filter((v) => v !== selectedOption)
          : [...value, selectedOption];
      } else {
        newSelectedValues = [selectedOption];
      }
      if (selectable) {
        setSelectedIndexes(
          newSelectedValues.map((v) => options.findIndex((o) => o.value === v))
        );
      }
      onChange(selectedOption as SingleOrArray<T, Multiple>); // Casting to SingleOrArray<T, Multiple>
    } else {
      if (selectable) setSelectedIndexes([index]);
      onChange(selectedOption as SingleOrArray<T, Multiple>); // Casting to SingleOrArray<T, Multiple>
      handleOpenChange(false);
    }
  };

  // Ensure selectedIndexes are valid before accessing options
  const selectedLabels = selectedIndexes
    .filter((index) => options[index]) // Filter out invalid indexes
    .map((index) => options[index].label)
    .join(', ');

  const selected =
    selectedIndexes.length && options[selectedIndexes[0]]
      ? options[selectedIndexes[0]]
      : null;

  const renderPlaceholder = () => (
    <span className="text-slate-500">
      {placeholder ?? <FormattedMessage defaultMessage="Select" id="kQAf2d" />}
    </span>
  );

  let selectedLabelDisplay;

  if (multiple) {
    selectedLabelDisplay =
      selectedLabels.length > 0 ? selectedLabels : renderPlaceholder();
  } else {
    selectedLabelDisplay = selected?.label ?? renderPlaceholder();
  }

  const label = (label: string, tooltip?: boolean) => {
    const labelEl = <span className="truncate">{label}</span>;
    return tooltip ? <Tooltip title={label}>{labelEl}</Tooltip> : labelEl;
  };

  return (
    <>
      <div
        tabIndex={0}
        ref={refs.setReference}
        aria-autocomplete="none"
        {...getReferenceProps()}
        className={cx(
          'inline-flex min-h-9 cursor-pointer items-center gap-2 truncate rounded-input border border-slate-200 pl-2.5 pr-2 py-1 text-sm font-medium shadow-sm',
          disabled
            ? 'pointer-events-none cursor-default border-slate-200 bg-slate-100 text-slate-400'
            : 'border-slate-200 hover:border-slate-400/50 hover:bg-slate-25',
          variant === 'naked' ? 'border-transparent' : '',
          full ? 'w-full' : 'max-w-64',
          'focus:outline-none focus-visible:border-brand-500 focus-visible:ring-2 focus-visible:ring-brand-100'
        )}
      >
        {selected?.icon && <div className="flex-shrink-0">{selected.icon}</div>}
        <span className="truncate">{selectedLabelDisplay}</span>
        <span className="ml-auto flex-shrink-0">
          {loading ? <Spinner /> : <ChevronDown size="1rem" />}
        </span>
      </div>
      {isOpen && (
        <FloatingFocusManager context={context} modal={false}>
          <FloatingPortal root={root}>
            <div
              ref={refs.setFloating}
              {...getFloatingProps()}
              style={floatingStyles}
              className="z-Menu flex max-h-[30vh] max-w-64 flex-col gap-1 overflow-auto rounded-lg border border-slate-200 bg-white p-2 shadow-lg shadow-slate-600/20 focus:outline-none"
            >
              {options.map((o, i) => (
                <Fragment key={String(o.value)}>
                  {o.divider === 'top' && <hr className="-mx-2 my-1" />}
                  <div
                    ref={(node) => {
                      listRef.current[i] = node;
                    }}
                    role="option"
                    tabIndex={i === activeIndex ? 0 : -1}
                    aria-selected={selectedIndexes.includes(i)}
                    {...getItemProps({
                      onClick: () => handleSelect(i),
                      onKeyDown: (event) => {
                        if (event.key === 'Enter') {
                          event.preventDefault();
                          handleSelect(i);
                        }

                        if (event.key === ' ' && !isTypingRef.current) {
                          event.preventDefault();
                          handleSelect(i);
                        }
                      },
                    })}
                    className={cx(
                      'flex flex-shrink-0 cursor-pointer items-center gap-2 overflow-hidden truncate rounded px-2 py-1 text-left text-sm',
                      selectedIndexes.includes(i)
                        ? 'bg-brand-500 text-white focus:bg-brand-500'
                        : 'bg-white',
                      'disabled:cursor-default',
                      'focus:bg-brand-100 focus:outline-none',
                      o.divider === 'top' ? 'border-t-slate-300' : '',
                      o.divider === 'bottom' ? 'border-b-slate-300' : ''
                    )}
                  >
                    <span aria-hidden className="size-4 flex-shrink-0">
                      {selectedIndexes.includes(i) && <Check size="1rem" />}
                    </span>
                    {o.icon && <div className="flex-shrink-0">{o.icon}</div>}
                    {label(o.label, o.tooltip)}
                  </div>
                  {o.divider === 'bottom' && <hr className="-mx-2 my-1" />}
                </Fragment>
              ))}
            </div>
          </FloatingPortal>
        </FloatingFocusManager>
      )}
    </>
  );
}
