import React from 'react';
import { cx } from '../../helpers/utils';

export const DescriptionListItemContentSubItem: React.FC<{
  icon: React.ReactNode;
  label: React.ReactNode;
  secondaryLabel?: React.ReactNode;
  actions: React.ReactNode;
  className?: string;
}> = ({ icon, label, secondaryLabel, actions, className }) => {
  const Icon = icon;
  return (
    <li
      className={cx(
        'flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6',
        className
      )}
    >
      <div className="flex flex-1 items-center">
        {Icon}
        <div className="ml-4 flex min-w-0 flex-1 gap-2">
          <span className="truncate font-medium">{label}</span>
          {secondaryLabel && (
            <span className="flex-shrink-0 text-slate-400">
              {secondaryLabel}
            </span>
          )}
        </div>
      </div>
      <div className="ml-4 flex-shrink-0">{actions}</div>
    </li>
  );
};
