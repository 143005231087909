import { WorkflowStatus } from '@tactiq/model';
import React, { ReactElement } from 'react';
import { Spinner } from '../../components/Spinner';

const Dot = ({ className }: { className: string }) => (
  <div className={`ml-1 mr-2 size-2 rounded-full ${className}`} />
);

export function WorkflowStatusIcon(props: {
  status: WorkflowStatus;
}): ReactElement {
  switch (props.status) {
    case WorkflowStatus.PENDING:
      return <Spinner className="mr-1 size-4" />;

    case WorkflowStatus.COMPLETE:
      return <Dot className="bg-green-500" />;

    case WorkflowStatus.NOT_STARTED:
      return <Dot className="!size-3 border border-dashed border-slate-600" />;

    case WorkflowStatus.WAITING_FOR_CONFIRMATION:
      return <Dot className="bg-blue-500" />;

    case WorkflowStatus.TIMED_OUT:
      return <Dot className="bg-yellow-400" />;

    case WorkflowStatus.FAILED:
      return <Dot className="bg-red-500" />;

    case WorkflowStatus.SKIPPED:
      return <Dot className="bg-slate-400" />;
  }
}
